import React from 'react';
import { useEffect, useRef, useState } from 'react';

import { atom, useRecoilState } from 'recoil';

import API from '@root/ApiClient';

import { ResizeHandle } from './BartResizer';

const tabState = atom({
  key: 'tab',
  default: 'MESSAGES',
});

export function BartDebug({ threadID, open, width, handleResize }) {
  const [tab] = useRecoilState(tabState);

  return (
    <div
      style={{
        position: 'fixed',
        left: open ? 0 : -width,
        top: 0,
        height: '100%',
        width: width,
        transition: 'left 0.3s ease',
        backgroundColor: 'black',
        color: '#abb2bf',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TitleBar />

      {tab === 'MESSAGES' && <Messages threadID={threadID} open={open} />}
      {tab === 'RUNS' && <div>TODO: runs list</div>}

      <ResizeHandle
        onResize={handleResize}
        style={{ backgroundColor: 'rgba(255,255,255,20%)', width: 10, height: 40, cursor: 'ew-resize' }}
      />
    </div>
  );
}

//----------------------------------------------------------------------------------------
// BartDebug Subcomponents
//----------------------------------------------------------------------------------------
function TitleBar() {
  return (
    <div
      style={{
        fontWeight: 'bold',
        fontSize: 'larger',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        flexShrink: 0,
        padding: '5px',
        display: 'flex',
        flexDirection: 'row',
        marginRight: 10,
        borderBottom: '1px solid red',
      }}
    >
      <div>Bart Debug</div>
      <div style={{ flexGrow: 1 }} />
      <Tabs />
    </div>
  );
}

function Tabs() {
  const [tab, setTab] = useRecoilState(tabState);

  const makeStyle = (tabKey) => {
    if (tab === tabKey) {
      return { color: 'hsl(210, 100%, 65%', textDecoration: 'underline' };
    } else {
      return { color: 'hsl(210, 80%, 50%' };
    }
  };

  const NavLink = ({ label, tabKey }) => {
    return (
      <a
        style={makeStyle(tabKey)}
        onClick={() => {
          setTab(tabKey);
        }}
      >
        {label}
      </a>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: 15, marginRight: 10 }}>
      <NavLink label="Messages" tabKey={'MESSAGES'} />
      <NavLink label="Runs" tabKey={'RUNS'} />
    </div>
  );
}

function Messages({ threadID, open }) {
  const containerRef = useRef(null);

  const [messages, setMessages] = useState([]);

  const loadMessages = async () => {
    const res = await API.call('bart', {
      action: 'FETCH_THREAD_MESSAGES',
      threadID,
    });
    // TODO: error handling!

    setMessages(res.messages);
  };

  useEffect(() => {
    if (!open) return;
    loadMessages();
  }, [open]);

  useEffect(() => {
    const el = containerRef.current;
    if (!el) return;
    el.scrollTop = el.scrollHeight;
  }, [messages]);

  return (
    <>
      <div ref={containerRef} style={{ overflow: 'auto', flexGrow: 1, padding: '10px' }}>
        {messages.map(({ id, created_at, role, content }) => {
          const text = content?.[0]?.text?.value;
          if (!text) return undefined;

          const displayRole = role === 'user' ? 'User' : 'Bart';
          const roleColor = role === 'user' ? '#61afef' : '#c678dd';
          const timestamp = new Date(created_at * 1000).toLocaleString();

          return (
            <div
              key={id}
              style={{
                borderLeft: '3px solid',
                borderLeftColor: roleColor,
                paddingLeft: '10px',
                marginBottom: '16px',
                fontSize: '11px',
              }}
            >
              <div style={{ marginBottom: '4px' }}>
                <span style={{ fontSize: '13px', fontWeight: 'bold', color: roleColor }}>{displayRole}</span> -{' '}
                <span style={{ color: 'hsl(109, 100.00%, 81.40%)' }}>{timestamp}</span>
              </div>
              <div style={{ whiteSpace: 'pre-wrap' }}>{text}</div>
            </div>
          );
        })}
      </div>
      <div style={{ color: 'black', flexShrink: 0, margin: 5 }}>
        <button
          onClick={() => {
            loadMessages();
          }}
        >
          Reload
        </button>
      </div>
    </>
  );
}
