import React, { useEffect, useState } from 'react';

export function useResizer({ defaultDimensions, storageKey }) {
  const [dimensions, setDimensions] = useState(() => {
    const saved = localStorage.getItem(storageKey);
    return saved ? JSON.parse(saved) : defaultDimensions;
  });

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(dimensions));
  }, [dimensions]);

  const handleResize = (e) => {
    e.preventDefault();
    const startWidth = dimensions.width;
    const startHeight = dimensions.height;
    const startX = e.clientX;
    const startY = e.clientY;

    const onMouseMove = (moveEvent) => {
      const minWidth = 360;
      const minHeight = 270;
      const newWidth = Math.max(minWidth, startWidth + moveEvent.clientX - startX);
      const newHeight = Math.max(minHeight, startHeight - (moveEvent.clientY - startY));
      setDimensions({ width: newWidth, height: newHeight });
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  return [dimensions, handleResize];
}

export function ResizeHandle({ onResize, style = {} }) {
  return (
    <div
      style={{
        position: 'absolute',
        right: '0px',
        top: '0px',
        backgroundColor: 'rgba(0,0,0,20%)',
        width: '20px',
        height: '20px',
        cursor: 'nesw-resize',
        ...style,
      }}
      onMouseDown={onResize}
    ></div>
  );
}
