import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormGroup, Modal } from 'react-bootstrap';

import SearchParams, { DEFAULT_COLS, LENS_COLS, TRASH_COLS } from '@core/models/SearchParams';
import { FEATURES } from '@core/models/Team';

import { Button, ButtonIcon, Switch } from '@components/dmp';

import TeamSelector from '@components/teams/TeamSelector';
import Fire from '@root/Fire';

import { findColumn } from '../deal/Columns';
import ColumnTag from '../reports/ColumnTag';
import ColumnsManager from '../reports/ColumnsManager';
import TemplateSelector from '../teams/TemplateSelector';
import TemplateVariableSearch from './TemplateVariableSearch';

@autoBindMethods
export default class ColumnSettings extends Component {
  static defaultProps = {
    canEditTemplate: false,
    columns: [],
    dealTemplate: null,
    defaultColumns: DEFAULT_COLS,
    onSave: _.noop,
    teams: null,
    title: 'Select Columns',
    limit: null,
  };

  static propTypes = {
    canEditTemplate: PropTypes.bool, // This requires a "searchParams" prop
    close: PropTypes.func.isRequired,
    columns: PropTypes.array,
    defaultColumns: PropTypes.array,
    limit: PropTypes.number,
    searchParams: PropTypes.instanceOf(SearchParams),
    onSave: PropTypes.func,
    show: PropTypes.bool.isRequired,
    teams: PropTypes.array,
    title: PropTypes.string,
    isTrash: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedColumns: [],
      searchedVariables: [],
      selectedTeam: null,
      teamTemplates: [],
      crossTemplateEnabled: false,
      selectedTemplate: null,
      dealTemplate: null,
      hideDefaultCols: false,
      hideLensCols: false,
      hideCrossCols: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadColumns(this.props.columns);
    this.setSearchParams();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { dealTemplate, columns, show, defaultColumns, searchParams } = this.props;
    const { selectedTeam } = this.state;

    const curCols = columns.toString(),
      prevCols = prevProps.columns.toString();

    if (
      curCols !== prevCols ||
      _.get(dealTemplate, 'dealID') !== _.get(prevProps, 'dealTemplate.dealID') ||
      (show && !prevProps.show && this._isMounted)
    ) {
      this.loadColumns(columns);
      this.setSearchParams();
    }

    //if a team was changed to different team reset filters/managed columns
    if (selectedTeam && prevState.selectedTeam?.teamID !== selectedTeam.teamID && this._isMounted) {
      //If the team selected is the activly used team in search params load back in all of the activly selected columns
      const updatedColumns = selectedTeam?.teamID === searchParams.teamID ? columns : defaultColumns;
      this.setState({ searchedVariables: [] });
      this.onColumnsChange([...updatedColumns]);
      this.setSearchParams();
    }
  }

  //Reworked - we will only apply filters upon actually hitting apply.
  //This means when we first load the component/update search params we need to
  //set them to state values so they are always up to date.
  async setSearchParams() {
    const { searchParams } = this.props;

    let selectedTeam = null;

    const hasTeam = searchParams.teamID || this.state.selectedTeam;
    const crossTemplateEnabled = !!searchParams.crossTemplate || this.state.crossTemplateEnabled;

    if (hasTeam) {
      selectedTeam = this.state.selectedTeam
        ? this.state.selectedTeam
        : await API.call('getTeam', { teamID: searchParams.teamID });
    }

    await this.getTeamVariables(selectedTeam?.teamID || null);

    this.setState({
      crossTemplateEnabled,
      selectedTeam,
    });
  }

  //When we reload a filter with cross template enabled we need to grab all of the non default column search params and configure them as columns
  async loadTeamVariableColumns(teamTemplates, teamID) {
    const { defaultColumns, columns, searchParams } = this.props;

    if (!!searchParams.crossTemplate) {
      //Remove all default columns before processing team columns
      const teamVariables = _.filter(columns, (col) => {
        return (
          !_.find(defaultColumns, (key) => {
            return key === col;
          }) &&
          !_.find(LENS_COLS, (key) => {
            return key === col;
          })
        );
      });

      //only rerun this if we have added a new elements to the cross template columns
      if (this.state.searchedVariables.length !== teamVariables.length && teamID === searchParams.teamID) {
        const searchedVariables = [];
        for await (const key of teamVariables) {
          for await (const template of teamTemplates) {
            let column = findColumn(key, template);
            if (column) {
              searchedVariables.push({ key, template });
            }
          }
        }
        this.setState({ searchedVariables: _.uniqBy(searchedVariables, 'key') });
      }
    }
  }

  //Whenever we load in a team we need to get all the teams templates and extract their variables.
  async getTeamVariables(teamID) {
    if (!teamID) return;
    const templates = await API.call('getTeamTemplates', { teamID, activeOnly: true });
    const teamTemplates = await Promise.all(
      _.map(templates, async (template) => {
        return await Fire.getDeal(template.dealID);
      })
    );

    const team = _.find(this.props.teams, { teamID });
    //This will run whenever the searchedVariables !== the non default colums
    //AKA when we first load old filters and if we have added a new one column via search
    this.loadTeamVariableColumns(teamTemplates, teamID);

    this.setState({ selectedTeam: team, teamTemplates });
  }

  get hasChanges() {
    const { columns, defaultColumns } = this.props;
    const { selectedColumns } = this.state;

    // If no columns are specified yet, compare to the default columns string
    const compare = columns.join(',') || defaultColumns.join(',');
    return compare !== selectedColumns.join(',');
  }

  async loadColumns(columns) {
    this.setState({ selectedColumns: [...columns] });
  }

  onColumnsChange(columns = []) {
    this.setState({ selectedColumns: columns });
  }

  handleColumnToggle(col, add) {
    const { selectedColumns } = this.state;
    const colIdx = selectedColumns.indexOf(col);

    if (add) {
      if (colIdx === -1) {
        selectedColumns.push(col);
        this.setState({ selectedColumns });
      }
    } else {
      if (colIdx > -1) {
        selectedColumns.splice(colIdx, 1);
        this.setState({ selectedColumns });
      }
    }
  }

  applyColumns() {
    const { close, onSave, searchParams } = this.props;
    const { selectedColumns, crossTemplateEnabled, selectedTeam, selectedTemplate } = this.state;

    searchParams.selectTeam(selectedTeam);
    searchParams.crossTemplate = crossTemplateEnabled;
    searchParams.selectTemplate(selectedTemplate);
    onSave(selectedColumns);
    close();
  }

  async selectTemplate(template) {
    const { dealID } = template;
    const dealTemplate = await Fire.getDeal(dealID);
    this.setState({ selectedTemplate: template, dealTemplate });
  }

  async clearTemplate() {
    this.setState({ selectedTemplate: null, dealTemplate: null });
  }

  toggleCrossTemplate() {
    const { defaultColumns } = this.props;
    const { crossTemplateEnabled } = this.state;

    //reset all feilds on toggle.
    this.setState({
      selectedTemplate: null,
      dealTemplate: null,
      searchedVariables: [],
      selectedTeam: null,
      teamTemplates: [],
      selectedColumns: [],
    });

    this.onColumnsChange([...defaultColumns]);
    this.setState({ crossTemplateEnabled: !crossTemplateEnabled });
  }

  selectTeam(teamID) {
    const team = _.find(this.props.teams, { teamID }) || null;
    this.setState({
      selectedTeam: team,
      selectedTemplate: null,
      dealTemplate: null,
      searchedVariables: [],
      teamTemplates: [],
      selectedColumns: [],
    });
  }

  clearTeam() {
    this.setState({
      selectedTeam: null,
      selectedTemplate: null,
      dealTemplate: null,
      searchedVariables: [],
      teamTemplates: [],
      selectedColumns: [],
    });
  }

  renderColumnTag(key, idx, template) {
    const { crossTemplateEnabled, dealTemplate } = this.state;
    const added = this.state.selectedColumns.includes(key);

    return (
      <li key={idx}>
        <ColumnTag
          checked={added}
          columnKey={key}
          dealTemplate={crossTemplateEnabled ? template : dealTemplate}
          onChange={this.handleColumnToggle}
        />
      </li>
    );
  }

  render() {
    const { show, close, teams, limit, canEditTemplate, title, user, isTrash, searchParams } = this.props;
    const {
      selectedColumns,
      hideDefaultCols,
      hideLensCols,
      hideCrossCols,
      searchedVariables,
      crossTemplateEnabled,
      selectedTeam,
      teamTemplates,
      dealTemplate,
      selectedTemplate,
    } = this.state;

    const varCols = !dealTemplate ? [] : _.map(dealTemplate.filterableVariables, (v) => `v.${v.name}`);
    const lensEnabled = selectedTeam?.features ? selectedTeam.features[FEATURES.LENS.key] : false;

    return (
      <Modal dialogClassName="column-settings" show={show} onHide={close} data-cy="modal-column-settings">
        <Modal.Header closeButton>
          <span className="headline">{title}</span>
        </Modal.Header>
        <Modal.Body>
          <div className="two-col">
            <div className="col available">
              <Switch
                checked={crossTemplateEnabled}
                onChange={() => this.toggleCrossTemplate()}
                size="small"
                className="cross-template-switch"
                data-cy="cross-template-switch"
              >
                Cross-template variable columns
              </Switch>
              {limit && <div className="list-title">Select a maximum of {limit} columns</div>}

              {!crossTemplateEnabled && canEditTemplate && (
                <>
                  <div className="list-title">Select Template</div>
                  <FormGroup>
                    <TeamSelector
                      teams={teams}
                      teamID={selectedTeam?.teamID || null}
                      onSelect={this.selectTeam}
                      onClear={this.clearTeam}
                      size="small"
                      user={user}
                    />
                  </FormGroup>
                  <FormGroup>
                    <TemplateSelector
                      disabled={!selectedTeam}
                      team={selectedTeam}
                      onSelect={this.selectTemplate}
                      onClear={this.clearTemplate}
                      selectedTemplateKey={selectedTemplate?.key || null}
                      size="small"
                    />
                  </FormGroup>
                </>
              )}

              {crossTemplateEnabled && canEditTemplate && (
                <>
                  <div className="list-title">Select Team</div>
                  <FormGroup>
                    <TeamSelector
                      onSelect={(teamID) => {
                        this.selectTeam(teamID);
                        this.getTeamVariables(teamID);
                      }}
                      onClear={this.clearTeam}
                      teamID={selectedTeam?.teamID || null}
                      teams={teams}
                      user={user}
                      size="small"
                    />
                  </FormGroup>
                </>
              )}

              {!!varCols.length && canEditTemplate && !crossTemplateEnabled && (
                <div className="list-title">Template columns</div>
              )}

              {!dealTemplate && canEditTemplate && !crossTemplateEnabled && (
                <div className="empty">
                  Select a Template above in order to reveal additional variables that can be shown as columns.
                </div>
              )}

              {teamTemplates && canEditTemplate && teamTemplates.length > 0 && crossTemplateEnabled && (
                <TemplateVariableSearch
                  teamID={selectedTeam?.teamID}
                  teamTemplates={teamTemplates}
                  searchedVariables={searchedVariables}
                  onSelect={({ column, template }) => {
                    this.setState({
                      searchedVariables: [...searchedVariables, { key: column.key, template }],
                    });
                  }}
                />
              )}

              {!!varCols.length && (
                <ul className="cols template-cols">
                  {varCols.map((variable, idx) => this.renderColumnTag(variable, idx, null))}
                </ul>
              )}

              {!!searchedVariables.length && (
                <div className="list-title">
                  Cross-template variables{' '}
                  <ButtonIcon
                    icon={hideCrossCols ? 'chevronUp' : 'chevronSmall'}
                    onClick={() => this.setState({ hideCrossCols: !hideCrossCols })}
                    size="default"
                  />
                </div>
              )}
              {!!searchedVariables.length && !hideCrossCols && (
                <ul className="cols template-cols" data-cy="template-cols">
                  {searchedVariables.map((variable, idx) => this.renderColumnTag(variable.key, idx, variable.template))}
                </ul>
              )}

              <div className="list-title">
                Default columns{' '}
                <ButtonIcon
                  icon={hideDefaultCols ? 'chevronUp' : 'chevronSmall'}
                  onClick={() => this.setState({ hideDefaultCols: !hideDefaultCols })}
                  size="default"
                />
              </div>
              {!hideDefaultCols && (
                <ul className="cols default-cols">
                  {searchParams.deleted
                    ? TRASH_COLS.map((col, idx) => this.renderColumnTag(col, idx, null))
                    : DEFAULT_COLS.map((col, idx) => this.renderColumnTag(col, idx, null))}
                </ul>
              )}

              {lensEnabled && (
                <div className="list-title">
                  Lens columns{' '}
                  <ButtonIcon
                    icon={hideLensCols ? 'chevronUp' : 'chevronSmall'}
                    onClick={() => this.setState({ hideLensCols: !hideLensCols })}
                    size="default"
                  />
                </div>
              )}
              {!hideLensCols && lensEnabled && (
                <ul className="cols lens-cols" data-cy="lens-cols">
                  {LENS_COLS.map((col, idx) => this.renderColumnTag(col, idx, null))}
                </ul>
              )}
            </div>

            <div className="col current" data-cy="current-active-columns">
              <ColumnsManager
                teamTemplates={teamTemplates}
                dealTemplate={dealTemplate}
                columns={selectedColumns}
                onChange={this.onColumnsChange}
                onRemoveColumn={(col) => this.handleColumnToggle(col, false)}
                isTrash={isTrash}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="spacer" />
          <Button
            size="small"
            dmpStyle="primary"
            onClick={this.applyColumns}
            disabled={!this.hasChanges}
            data-cy="btn-apply"
          >
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
