import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import qs from 'query-string';

import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

import { dt } from '@core/utils/Environment';
import { getParameterByName, isEmail, passwordValidated } from '@core/utils/Validation';

import { Alert, Button } from '@components/dmp';

import Auth, { PROVIDERS } from '@root/Auth';
import CONFIG from '@root/Config';
import Fire from '@root/Fire';

import SingleSignOnButton from './SingleSignOnButton';

const Terms = () => (
  <span className="login-terms" data-cy="login-terms">
    Using Outlaw means you agree to our <br />
    <a className="subtle-link" href="https://getoutlaw.com/terms-of-service/" target="_blank" rel="noopener noreferrer">
      Terms of Service
    </a>
    {' and '}
    <a className="subtle-link" href="https://getoutlaw.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
      Privacy Policy
    </a>
    .
  </span>
);

const MODES = {
  login: { key: 'login', title: 'Log In' },
  signup: { key: 'signup', title: 'Sign Up' },
  forgot: { key: 'forgot', title: 'Reset Password' },
  reset: { key: 'reset', title: 'Check your inbox' },
  link: { key: 'link', title: 'Sign in using a link' },
  openID: { key: 'openID', title: 'Continue with OpenID' },
};

const LOGIN_TYPES = [
  'Password',
  PROVIDERS.GOOGLE.name,
  PROVIDERS.MICROSOFT.name,
  PROVIDERS.FILEVINE.name,
  PROVIDERS.OPEN_ID.name,
];

@autoBindMethods
class Login extends Component {
  static defaultProps = {
    mode: 'login',
    loginTypes: LOGIN_TYPES,
    dotvine: false,
    usePopup: false,
  };

  static propTypes = {
    userFullName: PropTypes.string,
    userEmail: PropTypes.string,
    loginTypes: PropTypes.arrayOf(PropTypes.oneOf(LOGIN_TYPES)),
    mode: PropTypes.string,
    loginError: PropTypes.string,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    dotvine: PropTypes.bool,
    usePopup: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    const { userEmail, userFullName } = props;

    const mode = _.get(MODES, `${props.mode}.key`, MODES.login.key);

    this.state = {
      mode,
      loading: false,
      error: '',
      email: userEmail || '',
      password: '',
      // Fields for registration (signup)
      fullName: userFullName || '',
      isForgotEmailSent: false,
      recoveryEmail: '',
      resetSuccess: false,
      usePopup: props.usePopup,
    };
  }

  componentDidMount() {
    // Switch to popup mode if passed in the URL
    const searchParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    if (searchParams?.authPopup === 'true') {
      this.setState({ usePopup: true });
    }

    this._isMounted = true;
    this.setMode(this.props.mode);

    let resetSuccess = getParameterByName('resetSuccess');
    if (resetSuccess) {
      this.setState({ resetSuccess: resetSuccess });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.mode != this.state.mode) this.setMode(props.mode);
  }

  get mode() {
    return MODES[this.state.mode];
  }

  get title() {
    const params = qs.parse(window.location.search);
    const redirect = params.redirect;
    const resource = decodeURIComponent(redirect).split('/')[1]; //e.g. /templates/-NhcrS9eF33Ra6QCTZMc/editor

    if (this.mode.key === MODES.login.key && redirect) {
      switch (resource) {
        case 'templates':
          return <>Please sign in to view this&nbsp;template</>;
        case 'deals':
          return <>Please sign in to view this&nbsp;{dt}</>;
        default:
          return <>Please sign in to view this&nbsp;resource</>;
      }
    }

    return this.mode.title;
  }

  get showTerms() {
    return this.mode.key === MODES.login.key || this.mode.key === MODES.signup.key;
  }

  get isSignup() {
    return this.mode.key === MODES.signup.key;
  }

  get canSubmit() {
    const { email, password, fullName } = this.state;
    let canSubmit = isEmail(email) || passwordValidated(password) === true;

    if (canSubmit && this.isSignup) {
      canSubmit = fullName.trim().length > 0;
    }

    return canSubmit;
  }

  validatePassword(e) {
    if (this.isSignup) {
      const password = e.target.value;
      let error = '';

      const passwordCheck = passwordValidated(password);

      if (passwordCheck === false) {
        error =
          'Password must be at least 8 characters and include one uppercase and lowercase letter, one number, and one special character (!@#$%^&*()\\-_+.)';
      }

      this.setState({ password, error });
    }
  }

  setMode(mode) {
    this.setState({ mode: MODES[mode] ? MODES[mode].key : MODES.login.key });

    //reset the forgot password message.
    if (mode === 'forgot') {
      this.setState({ recoveryEmail: '', isForgotEmailSent: false });
    }
  }

  resetUI() {
    if (this._isMounted) {
      this.setState({
        email: '',
        error: '',
        loading: false,
        mode: MODES.login.key,
        password: '',
        isForgotEmailSent: false,
      });
    }
  }

  onLogin(event) {
    const { email, password } = this.state;

    event.preventDefault();

    if (!isEmail(email) || !password) return;

    this.setState({ loading: true, error: '' });

    Auth.emailLogin(email, password, this.onLoginSuccess, this.onLoginError);
  }

  onLoginSuccess(fromToken) {
    const { history, location } = this.props;
    const params = qs.parse(location.search);

    // When we get a previous token back from a successful login (see Auth.emailLogin),
    // in the case of "upgrading" from a guest account to an already-existing account,
    // we need to redirect to the mergeAccounts route for immediate account merging
    if (fromToken) {
      let url = `/mergeAccounts?fromToken=${fromToken}`;
      if (params.redirect) {
        url += `&redirect=${encodeURIComponent(params.redirect)}`;
      }

      history.push(url);
    }
    // Otherwise (normal login) just reset state
    else {
      this.resetUI();
    }
  }

  onLoginError(error) {
    this.setState({ loading: false, error });
  }

  async resetPassword(event) {
    event.preventDefault();

    const recoveryEmail = this.state.email;
    this.setState({ loading: true, error: '' });

    try {
      await API.callAnon('sendPasswordResetEmail', { email: recoveryEmail });
    } catch (err) {
      console.log(err);
    }
    this.setState({ isForgotEmailSent: true, loading: false, email: '', recoveryEmail });
  }

  async openIDVerifyEmail(event) {
    event.preventDefault();

    const email = this.state.email;
    this.setState({ loading: true, error: '' });

    let provider = null;

    try {
      provider = await API.callAnon('getOpenIDProvider', { email });
    } catch (err) {
      console.log(err);
    }

    if (!provider?.providerID) {
      this.setState({ error: 'OpenID provider not found. Please check your email address.', loading: false });
      return;
    }

    try {
      console.log(`Logging in with OpenID provider ${provider.providerID}`);
      Auth.login(provider.providerID, null, null, null, email);
    } catch (err) {
      this.setState({ error: 'There was a problem connecting to your OpenID provider.', loading: false });
      console.log('Failed start OpenID login', err);
    }
  }

  async sendSignInLink(event) {
    event.preventDefault();

    const recoveryEmail = this.state.email;
    this.setState({ loading: true, error: '' });

    try {
      await API.callAnon('sendSignInLink', { email: recoveryEmail });
      // Save locally to avoid asking the user again.
      window.localStorage.setItem('emailForSignIn', recoveryEmail);
    } catch (err) {
      console.log(err);
    }

    this.setState({ isForgotEmailSent: true, loading: false, email: '', recoveryEmail });
  }

  async onSignup(event) {
    const { email, password, fullName } = this.state;

    event.preventDefault();

    await this.setState({ loading: true, error: '' });

    try {
      const openIDCheck = await API.callAnon('getOpenIDProvider', { email });
      if (openIDCheck?.providerID) {
        this.setState({
          loading: false,
          error:
            'Email/password is not supported for your email, please choose Continue with OpenID to create an account.',
        });
        return;
      }

      const newAccount = await Auth.signup(email, password);
      await Fire.saveUserInfo({ id: newAccount.uid }, { fullName });
      await API.call('indexUser');
      console.log(`[AUTH] New email/pw user name saved`);

      this.resetUI();
    } catch (error) {
      console.log(error);
      if (this._isMounted) {
        this.setState({ loading: false, error });
      }
    }
  }

  renderInstructions() {
    switch (this.mode.key) {
      case MODES.login.key:
        return (
          <span>
            Need an Outlaw account?{' '}
            <a className="highlight" onClick={() => this.setMode(MODES.signup.key)} data-cy="create-account">
              Create an account
            </a>
          </span>
        );
      case MODES.signup.key:
        return (
          <span>
            Already signed up?{' '}
            <a className="highlight" onClick={() => this.setMode(MODES.login.key)}>
              Log in
            </a>
          </span>
        );
      case MODES.forgot.key:
        return (
          <span>
            Enter your email address and we'll send you <br />a link to reset your password.
          </span>
        );
      case MODES.reset.key:
        return (
          <span>
            A link to reset your password has been sent <br />
            to the email address you provided.
          </span>
        );
      default:
        return '';
    }
  }

  renderError() {
    const { error } = this.state;
    return (
      error && (
        <Alert className="login-error" dmpStyle="danger" data-cy="login-error">
          {error}
        </Alert>
      )
    );
  }

  renderLogin() {
    const { resetSuccess, usePopup } = this.state;
    const { loginError, loginTypes } = this.props;

    // Re-order login types to ensure that the Password is always the latest
    const orderedLoginTypes = _.sortBy(loginTypes, (loginType) => _.find(PROVIDERS, { name: loginType }));

    return (
      <>
        {loginError && (
          <Alert className="login-error" dmpStyle="danger">
            {loginError}
          </Alert>
        )}
        {resetSuccess && <Alert dmpStyle="success">Password updated, please log in</Alert>}
        {_.map(orderedLoginTypes, (loginType) => {
          if (loginType === 'Password') {
            return this.renderLoginPassword();
          } else if (loginType === PROVIDERS.OPEN_ID.name) {
            // Render OpenID differently than the other SSO providers since it's a 2 step process
            return (
              CONFIG.OPEN_ID_ENABLED && (
                <Button block={true} onClick={() => this.setMode(MODES.openID.key)} className="sso-button">
                  <img src={`/assets/svg/login-openid.svg`} alt="OpenID" />
                  <span>Continue with OpenID</span>
                </Button>
              )
            );
          } else {
            const provider = _.find(PROVIDERS, { name: loginType });
            if (!provider) return;

            if (loginType === PROVIDERS.FILEVINE.name && !CONFIG.FVID_ENABLED) {
              return;
            }

            return (
              <SingleSignOnButton
                key={provider.id}
                domain={provider.id}
                providerName={provider.name}
                textTemplate="Continue with [PROVIDER]"
                usePopup={usePopup}
                block
              />
            );
          }
        })}
      </>
    );
  }

  renderLoginPassword() {
    const { loginTypes, dotvine } = this.props;
    const { email, password, fullName, loading } = this.state;

    const isSignup = this.isSignup;
    const onSubmit = isSignup ? this.onSignup : this.onLogin;

    return (
      <form className="form-fields" data-cy="login-form-fields" onSubmit={onSubmit} key="password">
        {loginTypes.length > 1 && (
          <div className="or">
            <div>Or</div>
          </div>
        )}
        <FormGroup>
          <div className="contents">
            <ControlLabel>Email address</ControlLabel>
            <FormControl
              className="email"
              name="email"
              onChange={(e) => this.setState({ email: e.target.value })}
              placeholder="you@company.com"
              type="text"
              value={email}
              data-cy="login-email"
              disabled={dotvine && isSignup}
            />
          </div>
        </FormGroup>

        <FormGroup>
          <div className="contents">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              className="password"
              name="password"
              onChange={(e) => this.setState({ password: e.target.value })}
              onBlur={this.validatePassword}
              placeholder={isSignup ? 'New Password' : 'Password'}
              type="password"
              value={password}
              data-cy="login-password"
            />
          </div>
        </FormGroup>

        {isSignup && !dotvine && (
          <FormGroup>
            <div className="contents">
              <ControlLabel>Full name</ControlLabel>
              <FormControl
                className="full-name"
                type="text"
                value={fullName}
                placeholder="First Name + Last Name"
                onChange={(e) => this.setState({ fullName: e.target.value })}
                data-cy="signup-name"
              />
            </div>
          </FormGroup>
        )}

        <Button
          dmpStyle="primary"
          type="submit"
          block
          loading={loading}
          disabled={loading || !this.canSubmit}
          data-cy="btn-login"
        >
          {isSignup ? 'Sign up' : 'Log in'}
        </Button>

        <div className="footer-login" data-cy="footer-login">
          <a onClick={() => this.setMode(MODES.forgot.key)} data-cy="forgot-password">
            Forgot Password?
          </a>
        </div>
      </form>
    );
  }

  renderForm() {
    const { email, isForgotEmailSent, loading } = this.state;

    const wrapperClassNames = cx('login-wrapper', 'flex-middle-inner');

    if (this.mode.key === 'link') {
      return (
        <div className={wrapperClassNames}>
          {this.renderError()}
          {isForgotEmailSent && (
            <Alert dmpStyle="success">
              If your email address was entered correctly, you should receive an email with instructions on how to reset
              your password shortly.
            </Alert>
          )}
          {!isForgotEmailSent && (
            <form className="form-fields" onSubmit={this.sendSignInLink}>
              <FormGroup>
                <div className="contents">
                  <FormControl
                    className="email"
                    type="text"
                    value={email}
                    placeholder="Email address"
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </div>
              </FormGroup>

              <Button dmpStyle="primary" type="submit" block loading={loading} disabled={loading || !isEmail(email)}>
                Email me a sign in link
              </Button>
            </form>
          )}

          <div className="footer-login">
            <a className="subtle-link" onClick={() => this.setMode(MODES.login.key)}>
              Back to login
            </a>
          </div>
        </div>
      );
    }

    if (this.mode.key === 'reset') {
      return (
        <div className={wrapperClassNames}>
          <div className="reset-sent">
            <Button onClick={this.resetUI}>OK</Button>
          </div>
        </div>
      );
    }

    if (this.mode.key === 'forgot') {
      return (
        <div className={wrapperClassNames}>
          {this.renderError()}
          {isForgotEmailSent && (
            <Alert dmpStyle="success">
              If your email address was entered correctly, you should receive an email with instructions on how to reset
              your password shortly.
            </Alert>
          )}
          {!isForgotEmailSent && (
            <form className="form-fields" onSubmit={this.resetPassword}>
              <FormGroup>
                <div className="contents">
                  <FormControl
                    className="email"
                    type="text"
                    value={email}
                    placeholder="Email address"
                    onChange={(e) => this.setState({ email: e.target.value })}
                    data-cy="reset-passowrd-email"
                  />
                </div>
              </FormGroup>

              <Button
                dmpStyle="primary"
                type="submit"
                block
                loading={loading}
                disabled={loading || !isEmail(email)}
                data-cy="btn-reset-password"
              >
                Email me a password reset link
              </Button>
            </form>
          )}

          <div className="footer-login">
            <a className="subtle-link" onClick={() => this.setMode(MODES.login.key)} data-cy="back-to-login">
              Back to login
            </a>
          </div>
        </div>
      );
    }

    if (this.mode.key === 'openID') {
      // The second step in the OpenID SSO flow
      return (
        <div className={wrapperClassNames}>
          {this.renderError()}
          <form className="form-fields" onSubmit={this.openIDVerifyEmail}>
            <FormGroup>
              <div className="contents">
                <FormControl
                  className="email"
                  type="text"
                  value={email}
                  placeholder="Email address"
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
            </FormGroup>

            <Button dmpStyle="primary" type="submit" block loading={loading} disabled={loading || !isEmail(email)}>
              Continue
            </Button>
          </form>

          <div className="footer-login">
            <a className="subtle-link" onClick={() => this.setMode(MODES.login.key)}>
              Back to login
            </a>
          </div>
        </div>
      );
    }

    return (
      <div className={wrapperClassNames}>
        {this.renderError()}
        {this.renderLogin()}
      </div>
    );
  }

  render() {
    const { dotvine } = this.props;

    return (
      <div>
        {!dotvine && <h2>{this.title}</h2>}
        <div>
          {!dotvine && (
            <div className="login-signup-titles" data-cy="login-signup-titles">
              {this.renderInstructions()}
            </div>
          )}
          {this.renderForm()}
          {!dotvine && this.showTerms && <Terms />}
        </div>
      </div>
    );
  }
}

export default Login;
